




































































import Vue from 'vue'

import { Spot } from '../../../../../../shared/interfaces'

export default Vue.extend({
  name: 'SpotForm',

  props: {
    spots: Array as () => Spot[],
    minHeight: String,
    submitting: Boolean
  },

  data () {
    return {
      spotForm: this.spots,
      required: [
        (v: string) => !!v || 'All fields are required'
      ]
    }
  },

  created () {
    if (this.spots.length === 0) {
      this.addSpot()
    }
  },

  methods: {
    addSpot () {
      this.spotForm.push({
        id: '',
        name: '',
        windguru: null
      })
    },

    deleteSpot (index: number) {
      this.spotForm.splice(index, 1)
    },

    submit () {
      // TODO:  State is not updated in this component for some weird reason...
      // v-model into this
      this.$emit('updateSpots', this.spotForm)
    }
  }
})
