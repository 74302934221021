


















































































import Vue from 'vue'

import { Board } from '../../../../../../shared/interfaces'

export default Vue.extend({
  name: 'BoardForm',

  props: {
    boards: Array as () => Board[],
    minHeight: String,
    submitting: Boolean
  },

  data () {
    return {
      boardsForm: this.boards,
      required: [
        (v: string) => !!v || 'All fields are required'
      ]
    }
  },

  computed: {
    brands (): string[] {
      const items = this.boards.map((boardItem: Board) => boardItem.brand)
      return items.filter((boardItem, i) => boardItem && items.indexOf(boardItem) === i)
    },

    models (): string[] {
      const items = this.boards.map((boardItem: Board) => boardItem.model)
      return items.filter((boardItem, i) => boardItem && items.indexOf(boardItem) === i)
    }
  },

  created () {
    if (this.boards.length === 0) {
      this.addBoard()
    }
  },

  methods: {
    addBoard () {
      this.boardsForm.push({
        brand: '',
        model: '',
        volume: ''
      })
    },

    deleteBoard (index: number) {
      this.boardsForm.splice(index, 1)
    },

    submit () {
      this.$emit('updateBoards', null, this.boardsForm)
    }
  }
})
