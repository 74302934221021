


















































































import Vue from 'vue'

import { Sail } from '../../../../../../shared/interfaces'

export default Vue.extend({
  name: 'SailForm',

  props: {
    sails: Array as () => Sail[],
    minHeight: String,
    submitting: Boolean
  },

  data () {
    return {
      sailsForm: this.sails,
      required: [
        (v: string) => !!v || 'All fields are required'
      ]
    }
  },

  computed: {
    // Put this function in a helper, it's also used in the boardForm

    brands (): string[] {
      const items = this.sails.map((sailItem: Sail) => sailItem.brand)
      return items.filter((sailItem, i) => sailItem && items.indexOf(sailItem) === i)
    },

    models (): string[] {
      const items = this.sails.map((sailItem: Sail) => sailItem.model)
      return items.filter((sailItem, i) => sailItem && items.indexOf(sailItem) === i)
    }
  },

  created () {
    if (this.sails.length === 0) {
      this.addSail()
    }
  },

  methods: {
    addSail () {
      this.sailsForm.push({
        brand: '',
        model: '',
        size: ''
      })
    },

    deleteSail (index: number) {
      this.sailsForm.splice(index, 1)
    },

    submit () {
      this.$emit('updateSails', this.sailsForm)
    }
  }
})
