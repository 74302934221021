






















import Vue from 'vue'
import snackbar from '../services/snackbar'

import SailForm from '../components/ui/form/gear/SailForm.vue'
import BoardForm from '../components/ui/form/gear/BoardForm.vue'

import { Gear, Sail, Board } from '../../../shared/interfaces'
import { UPDATE_GEAR, SHOW_SNACKBAR } from '../store/constants'
import { Snackbar } from '../interfaces'

export default Vue.extend({
  components: {
    SailForm,
    BoardForm
  },

  computed: {
    sails (): Sail[] {
      return this.$store.state.user.gear.sails.slice()
    },

    boards (): Board[] {
      return this.$store.state.user.gear.boards.slice()
    }
  },

  data () {
    return {
      submittingSails: false,
      submittingBoards: false
    }
  },

  methods: {
    async updateGear (sails: Sail[] | null, boards: Board[] | null): Promise<void> {
      const gear: Gear = {
        sails: sails || this.sails,
        boards: boards || this.boards
      }

      try {
        await this.$store.dispatch(UPDATE_GEAR, gear)

        snackbar.succes('Saved succesfully!')
        this.stopSubmitting()
      } catch (err) {
        snackbar.error()

        this.stopSubmitting()
      }
    },

    stopSubmitting () {
      this.submittingSails = false
      this.submittingBoards = false
    }
  }
})
